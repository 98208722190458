h4 {
  margin: 0;
  padding: 0;
}

#slide-home {
  display: block;
  position: relative;
  width: 330px;
  height: 250px;
  float: left;
  overflow: hidden;
}

.top-art-text {
  display: block;
  width: 125px;
  height: 75px;
  float: left;
  padding: 0 2px;
}
.top-art-img {
  display: block;
  width: 115px;
  height: 75px;
  margin-left: 131px;
  position: relative;
}

.feat-art-img {
  display: block;
  width: 90px;
  height: 70px;
  float: left;
  padding-left: 2px;
  margin-left: 4px;
}
.feat-art-text {
  display: block;
  width: 200px;
  height: 70px;
  margin-left: 150px;
  margin: 0px 0 0 95px;
  padding: 0 2px 0 2px;
}

.middle-section-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
}
.middle-section-container h1 {
  font-size: 1.4em;
  padding: 0em 1.5em;
}
.middle-section-container h2 {
  font-size: 1.4em;
  padding: 0em 1.5em;
  display: block;
  width: 100%;
  min-height: 30px;
  background-color: #ed145b;
  background-image: linear-gradient(to bottom, #A5053E 0%, #ED145B 100%);
  margin: 16px 0 0 0;
  margin: 0px;
  color: #fff;
  box-sizing: border-box;
}

#application img {
  box-sizing: border-box;
}
#application-slide {
  width: 230px;
  overflow: hidden;
}

.equi-img {
  display: block;
  width: 120px;
  height: 90px;
  float: left;
}
.equi-text {
  display: block;
  height: 90px;
  width: 126px;
  margin-left: 120px;
  padding-left: 4px;
}

#topright-content {
  display: block;
  width: 250px;
  height: 250px;
  overflow: hidden;
  position: relative;
  margin-left: 5px;
}

#archieve-art > h4,
#archieve-art h5,
#archieve-art h6,
#archieve-art p {
  padding: 0;
  margin: 0;
}

#article-slide {
  display: none;
}

.brandlogo > a {
  color: #6b7c92;
  fill: #6b7c92;
  display: flex;
  height: 100%;
  box-shadow: 0 4px 8px rgba(61, 82, 104, 0.12), 2px 0 8px rgba(22, 30, 39, 0.02);
  margin-left: 22px;
  border-radius: 4px;
  padding: 0 10px;
  background: #fff;
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.brandlogo {
  width: 150px;
  height: 150px;
  display: block;
  box-sizing: border-box;
}
.brandlogo > a:hover {
  text-decoration: none;
  transform: scale(1.05);
  color: #6772e4;
  fill: #6772e4;
}
.brandlogo img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.feature-blog .box-img,
.location .box-img,
.solution .box-img,
.featurebrand .box-img {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(63, 63, 63, 0.4);
}
.feature-blog .box-img img,
.location .box-img img,
.solution .box-img img,
.featurebrand .box-img img {
  width: 100%;
  max-height: 100%;
}

.box-img {
  position: relative;
}

.featurebrand .box-img {
  height: 250px;
}

.feature-blog .box-img img {
  max-height: 100%;
  max-width: 100%;
}

.slick-img img {
  height: 100%;
}

#sidebar-right,
#sidebar-left,
#menu-container {
  height: 100%;
}

nav.main,
picture.dynamic {
  grid-column: 1/-1;
}

picture.dynamic {
  grid-row: 4/6;
}

header {
  z-index: 2;
}

nav.main {
  top: -5px;
  z-index: 1;
}
nav.main > ul {
  display: flex;
  background-color: rgba(40, 113, 157, 0.9);
}
nav.main input#drop-concrete:checked ~ ul {
  max-height: 100%;
  display: block;
  background-color: rgba(49, 99, 157, 0.5);
}

.wrapper-box {
  box-shadow: none;
}

.photo {
  font-size: 12px;
}

.slick-slider-img {
  position: relative;
  display: block;
}

#banner-ad li {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 900px) {
  #feature-container {
    width: 1024px;
    height: 250px;
    box-sizing: border-box;
  }
  section.middle {
    margin: 5em 0em;
  }
  #home #banner-ad {
    width: 100%;
    position: relative;
    display: block;
    margin: 20px auto;
    width: 1024px;
    position: relative;
    display: block;
    margin: 0px auto;
    height: 380px;
  }
  nav.main li ul {
    background: rgb(45, 99, 157);
    display: none;
  }
  nav.main li:hover ul {
    position: absolute;
    top: 3rem;
    left: 0;
  }
  nav.main a:hover {
    color: #ccc;
  }
  nav.main .drop-concrete {
    display: none;
  }
  nav ul li ul li a {
    color: #ddd;
  }
}
.slick-slide img {
  max-height: 400px;
}

/********************/
@font-face {
  font-family: "external-link-font";
  src: url("data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBSoAAAC8AAAAYGNtYXAXVtKHAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5ZvFacWoAAAF4AAAAhGhlYWQIUvSYAAAB/AAAADZoaGVhBq0DxgAAAjQAAAAkaG10eAoAAAAAAAJYAAAAFGxvY2EAKABWAAACbAAAAAxtYXhwAAgAFQAAAngAAAAgbmFtZRdPKjEAAAKYAAACCnBvc3QAAwAAAAAEpAAAACAAAwMAAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADpAAPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6QD//f//AAAAAAAg6QD//f//AAH/4xcEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAACAAD/6wLrAtYACgASAAAXESEVIxEhNTMRIQE3JyERJwcnAAEAlQIVa/0VAVDFbwFFcMRnFQLra/3qwP7WAbfFbv68b8VnAAABAAAAAQAAratF9V8PPPUACwQAAAAAANMkWG0AAAAA0yRYbQAA/+sC6wLWAAAACAACAAAAAAAAAAEAAAPA/8AAAAQAAAAAAALrAAEAAAAAAAAAAAAAAAAAAAAFBAAAAAAAAAAAAAAAAgAAAAQAAAAAAAAAAAoAFAAeAEIAAQAAAAUAEwACAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAA4ArgABAAAAAAABABIAAAABAAAAAAACAAcAwwABAAAAAAADABIAVwABAAAAAAAEABIA2AABAAAAAAAFAAsANgABAAAAAAAGABIAjQABAAAAAAAKABoBDgADAAEECQABACQAEgADAAEECQACAA4AygADAAEECQADACQAaQADAAEECQAEACQA6gADAAEECQAFABYAQQADAAEECQAGACQAnwADAAEECQAKADQBKGV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdFZlcnNpb24gMS4wAFYAZQByAHMAaQBvAG4AIAAxAC4AMGV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdGV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdFJlZ3VsYXIAUgBlAGcAdQBsAGEAcmV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdEZvbnQgZ2VuZXJhdGVkIGJ5IEljb01vb24uAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgAAAAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "nav-arrow-font";
  src: url("data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBFEAAAC8AAAAYGNtYXAXVtKIAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zht2s6kAAAF4AAAAjGhlYWQJw9mHAAACBAAAADZoaGVhBZADxwAAAjwAAAAkaG10eApyANYAAAJgAAAAGGxvY2EAbgBGAAACeAAAAA5tYXhwAAgACQAAAogAAAAgbmFtZZlKCfsAAAKoAAABhnBvc3QAAwAAAAAEMAAAACAAAwImAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADpAQPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6QH//f//AAAAAAAg6QD//f//AAH/4xcEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAGv//gHOAn4ABgAAEwEXBxcHAWsBMTLq6jL+zwE+AUBH+fJOAUAAAQBrAAABzgKAAAYAADcnNyc3CQGcMerqMQEy/s4ATvL5R/7A/sAAAAEAAAABAABTZTjlXw889QALBAAAAAAA1GtLBgAAAADUa0sGAAD//gHOAoAAAAAIAAIAAAAAAAAAAQAAA8D/wAAABAAAAAAAAc4AAQAAAAAAAAAAAAAAAAAAAAYEAAAAAAAAAAAAAAACAAAAAjkAawI5AGsAAAAAAAoAFAAeADIARgAAAAEAAAAGAAcAAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAHAAAAAQAAAAAAAgAHAGAAAQAAAAAAAwAHADYAAQAAAAAABAAHAHUAAQAAAAAABQALABUAAQAAAAAABgAHAEsAAQAAAAAACgAaAIoAAwABBAkAAQAOAAcAAwABBAkAAgAOAGcAAwABBAkAAwAOAD0AAwABBAkABAAOAHwAAwABBAkABQAWACAAAwABBAkABgAOAFIAAwABBAkACgA0AKRpY29tb29uAGkAYwBvAG0AbwBvAG5WZXJzaW9uIDEuMABWAGUAcgBzAGkAbwBuACAAMQAuADBpY29tb29uAGkAYwBvAG0AbwBvAG5pY29tb29uAGkAYwBvAG0AbwBvAG5SZWd1bGFyAFIAZQBnAHUAbABhAHJpY29tb29uAGkAYwBvAG0AbwBvAG5Gb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@media screen and (max-width: 992px) {
  .nowrap {
    white-space: nowrap;
  }
  .overflow-hidden {
    overflow: hidden;
  }
}
@media screen and (max-width: 480px) {
  span.button.a {
    display: block;
  }
  section#feature-brand {
    width: 100%;
    height: 10em;
    box-sizing: border-box;
  }
  h2,
  h3 {
    text-align: center;
  }
  section > section.middle {
    margin: 0;
    padding: 0;
  }
  section.middle > div {
    margin: 2rem 0 0 0;
    width: 100%;
  }
  #banner-ad {
    margin-bottom: 2em;
  }
  .block .box > a {
    padding: 0;
  }
  .box a > div {
    padding: 0;
  }
  .middle .block > div {
    padding: 0;
  }
  .btn-link > a {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .main-slick-img {
    height: 380px;
    max-height: 400px;
    object-fit: cover;
  }
  .main-slick-img > img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
  #home .slick-img {
    height: 200px;
    max-height: 220px;
    overflow: hidden;
  }
  #home .feature-blog .slick-list {
    height: unset;
    max-height: unset;
    overflow: hidden;
  }
}
@media screen and (max-width: 899px) {
  #calculator h1 {
    font-size: 44px;
  }
  .slide {
    width: 100%;
  }
  nav.main {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
  }
  nav.main ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
  }
  nav.main ul ul {
    display: none;
  }
  nav.main ul li {
    width: 100%;
    display: block;
    padding: 1em 2em;
    border-bottom: 1px dashed rgb(45, 99, 157);
  }
  nav.main ul li:hover {
    background-color: rgba(45, 99, 157, 0.5);
  }
  nav.main ul li i {
    padding-right: 5px;
  }
  nav.main a {
    color: #ddd;
  }
  nav.main input.drop-concrete,
  nav.main .drop-concrete + a,
  nav.main #toggle {
    display: none;
  }
  label.drop-concrete {
    color: #ddd;
  }
  .slick-title {
    position: absolute;
    top: 10px;
    text-shadow: 3px 1px 5px rgba(105, 111, 105, 0.8);
  }
  #banner-ad .slick-bg + p {
    position: absolute;
    top: 60px;
    box-sizing: border-box;
    width: 80vw;
    height: 150px;
    color: #000;
  }
  label.toggle {
    position: relative;
    padding: 30px 25px;
    cursor: pointer;
  }
  .nav-icon {
    background-color: #000;
    width: 30px;
    height: 5px;
    display: block;
    position: relative;
    transition: all 0.2s ease;
  }
  .nav-icon::before, .nav-icon::after {
    position: absolute;
    content: "";
    background-color: #000;
    display: block;
    width: 30px;
    height: 5px;
    transition: all 0.2s ease;
  }
  .nav-icon::before {
    top: 10px;
  }
  .nav-icon::after {
    top: -10px;
  }
  #toggle:checked + label.toggle .nav-icon {
    transform: rotate(135deg);
  }
  #toggle:checked + label.toggle .nav-icon::before, #toggle:checked + label.toggle .nav-icon::after {
    transform: rotate(90deg);
    top: 0;
  }
}
#calculator h2 {
  font-size: 36px;
  color: #292929;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1.2em;
}
#calculator h3 {
  color: #505050;
  font-size: 26px;
  text-transform: uppercase;
  margin: 5px 0 0 0;
}
#calculator h4 {
  color: #505050;
  font-size: 20px;
  text-transform: uppercase;
  margin: 5px 0 0 0;
}

.btn-reset,
.btn-submit {
  font-size: 14px !important;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
}

.btn-reset {
  background-color: #929292;
}
.btn-submit {
  background-color: #004d9a;
}

.tighten {
  margin-bottom: 30px;
}

img {
  max-width: 100%;
}

a.btn[target=_blank]:after {
  font-family: "external-link-font";
  content: " \e900";
  line-height: 0;
  font-weight: normal;
}

#home #sidebar-right aside h3 {
  display: block;
  width: 100%;
  min-height: 35px;
  color: #036;
  background-image: none;
  line-height: 1.5;
}
#home #sidebar-right aside > div > div {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  text-align: center;
  background: #edf3f4;
  background-image: linear-gradient(to bottom, #ADD9E4 0%, #FFFFFF 100%);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  margin: 30px auto;
  padding: 8px;
}
#home section.concrete {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.col-01,
.col-02,
.col-03,
.col-04 {
  box-shadow: 1px 1px 4px 1px rgba(54, 53, 53, 0.2);
}

.box-container {
  margin: 0.5rem 1rem;
}

@media all and (max-width: 440px) {
  #sidebar-right {
    margin: 0 !important;
    padding: 0 !important;
  }
  .box-img {
    width: 100%;
    height: 200px;
  }
  .box-img a {
    width: 100%;
  }
  .box-img a img {
    width: 100%;
    height: 100%;
  }
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}