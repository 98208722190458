h4 {
    margin: 0;
    padding: 0;
}

#slide-home {
    display: block;
    position: relative;
    width: 330px;
    height: 250px;
    float: left;
    overflow: hidden;
}

.top-art {
    &-text {
        display: block;
        width: 125px;
        height: 75px;
        float: left;
        padding: 0 2px;
    }
    &-img {
        display: block;
        width: 115px;
        height: 75px;
        margin-left: 131px;
        position: relative;
    }
}

.feat-art {
    &-img {
        display: block;
        width: 90px;
        height: 70px;
        float: left;
        padding-left: 2px;
        margin-left: 4px;
    }
    &-text {
        display: block;
        width: 200px;
        height: 70px;
        margin-left: 150px;
        margin: 0px 0 0 95px;
        padding: 0 2px 0 2px;
    }
}

// .article {
//     background: url(../images/bg.png) -171px -1206px no-repeat;
//     // background-repeat: no-repeat;
//     // background-position: -171px -1206px;
//     display: block;
//     height: 45px;
//     width: 300px;
//     margin: 1px 0 1px 0;
//     &-img {
//         display: block;
//         width: 58px;
//         height: 36px;
//         float: left;
//         margin: 2px 2px 2px 11px;
//     }
//     &-text {
//         display: block;
//         width: 240px;
//         height: 36px;
//         margin-left: 60px
//     }
// }
.middle-section-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    h1 {
        font-size: 1.4em;
        padding: 0em 1.5em;
    }
    h2 {
        font-size: 1.4em;
        padding: 0em 1.5em;
        display: block;
        width: 100%;
        min-height: 30px;
        background-color: #ed145b;
        background-image: linear-gradient(to bottom, #A5053E 0%, #ED145B 100%);
        margin: 16px 0 0 0;
        margin: 0px;
        color: #fff;
        box-sizing: border-box;
    }
}
#application {
    img {
        box-sizing: border-box;
    }
    &-slide {
        width: 230px;
        overflow: hidden;
    }
}

.equi {
    &-img {
        display: block;
        width: 120px;
        height: 90px;
        float: left;
    }
    &-text {
        display: block;
        height: 90px;
        width: 126px;
        margin-left: 120px;
        padding-left: 4px;
    }
}

#topright-content {
    display: block;
    width: 250px;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin-left: 5px;
}

// #feat-article {
//     display: block;
//     width: 307px;
//     height: 85px;
//     background: url(../images/bg.png) 1px -1621px no-repeat;
//     margin: 5px auto;
//     color: #FFF;
//     padding: 15px 0 0 1px;
// }

// #archieve-art {
//     display: block;
//     width: 307px;
//     height: 96px;
//     background: url(../images/bg.png) 1px -1621px no-repeat;
//     margin: 2px auto;
//     color: #FFF;
//     padding: 4px 0 0 1px;
// }

#archieve-art {
    &>h4,
    h5,
    h6,
    p {
        padding: 0;
        margin: 0;
    }
}

article {
    // .top-article {
    //     background-image: url(../images/bg.png);
    //     background-repeat: no-repeat;
    //     background-position: -308px -1625px;
    //     display: block;
    //     height: 75px;
    //     width: 245px;
    //     margin-bottom: 4px;
    //     color: #fff;
    // }
}

// .top-equip {
//     background: url(../images/bg.png) 0 -1720px no-repeat;
//     display: block;
//     width: 250px;
//     height: 95px;
//     margin-top: 2px;
//     color: #fff;
// }

// #banner-ad {
//     ul,
//     li {
//         margin: 0;
//         padding: 0;
//         list-style: none;
//     }
// }

#article-slide {
    display: none;
}

.brandlogo {
    &>a {
        color: #6b7c92;
        fill: #6b7c92;
        display: flex;
        height: 100%;
        box-shadow: 0 4px 8px rgba(61, 82, 104, .12), 2px 0 8px rgba(22, 30, 39, .02);
        margin-left: 22px;
        border-radius: 4px;
        padding: 0 10px;
        background: #fff;
        position: relative;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }
}

.brandlogo {
    width: 150px;
    height: 150px;
    display: block;
    box-sizing: border-box;
    &>a:hover {
        text-decoration: none;
        transform: scale(1.05);
        color: #6772e4;
        fill: #6772e4;
    }
    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.feature-blog,
.location,
.solution,
.featurebrand {
    .box-img {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:rgba(63, 63, 63, 0.4);
        img{
            width: 100%;
            max-height: 100%;
            
        }
    }
}

.box-img {
    position: relative;
}

.featurebrand {
    .box-img {
        height: 250px;
    }
}

.feature-blog {
    .box-img {
        img {
            max-height: 100%;
            max-width: 100%;
        }
    }
}

.slick-img {
    img {
        height: 100%;
    }
}

#sidebar-right,
#sidebar-left,
#menu-container {
    height: 100%;
}

nav.main,
picture.dynamic {
    grid-column: 1/-1;
}

picture.dynamic {
    grid-row: 4/6;
}

header {
    z-index: 2;
}

nav.main {
    top: -5px;
    z-index: 1;
    &>ul {
        display: flex;
        background-color: rgba(40, 113, 157, 0.9);
    }
    input#drop-concrete:checked~ul {
        max-height: 100%;
        display: block;
        background-color: rgba(49, 99, 157, 0.5);
    }
}

.wrapper-box {
    box-shadow: none;
}

.photo {
    font-size: 12px;
}

.slick-slider-img {
    position: relative;
    display: block;
}

#banner-ad {
    li {
        position: relative;
        overflow: hidden;
    }
}

@media screen and (min-width: 900px) {
    #feature-container {
        width: 1024px;
        height: 250px;
        box-sizing: border-box;
    }
    section.middle {
        margin: 5em 0em;
    }
    #home {
        #banner-ad {
            width: 100%;
            position: relative;
            display: block;
            margin: 20px auto;
            width: 1024px;
            position: relative;
            display: block;
            margin: 0px auto;
            height: 380px;
        }
    }
    // #banner-ad {
    //     h2 {
    //         font-size: 5em;
    //         position: absolute;
    //         top: 10px;
    //         color: #ccc;
    //         text-shadow: 1px 2px 3px rgba(35, 35, 48, .8);
    //     }
    //     p {
    //         font-size: 3rem;
    //         position: absolute;
    //         top: 60%;
    //         right: 10em;
    //         width: 600px;
    //         color: #ccc;
    //         text-shadow: 2px 2px 1px rgba(35, 35, 35, 0.5);
    //         white-space: break-spaces;
    //         background-color: rgba(45, 99, 157, 0.2);
    //         padding: 0.5rem;
    //     }
    // }
    nav.main {
        li {
            ul {
                background: rgb(45, 99, 157);
                display: none;
            }
            &:hover ul {
                position: absolute;
                top: 3rem;
                left: 0;
            }
        }
        a:hover {
            color: #ccc;
        }
        .drop-concrete {
            display: none;
        }
    }
    nav ul li ul li a {
        color: #ddd;
    }
    // section.concrete {
    //     width: 1024px;
    //     box-sizing: border-box;
    // }
    // picture.dynamic {
    //     height: 450px;
    //     margin: 0;
    //     padding: 0;
    // }
    // .slide {
    //     width: 450px;
    // }
    // .concrete {
    //     border-top: 1px solid #ccc;
    //     border-bottom: 1px solid #ccc;
    //     margin: 1rem 0rem;
    // }
    // .content-container *,
    // #content,
    // section.middle {
    //     margin: 0;
    //     padding: 0;
    // }
    // #sidebar-right .block>div,
    // #sidebar-right .block>div:first-child,
    // #sidebar-right .block>div:first-child>label.title {
    //     margin: 0;
    // }
    // #weather-forecast {
    //     flex: 0 2 55%;
    // }
    // #application {
    //     flex: 0 1 40%;
    // }
    // .slick-img {
    //     height: 200px;
    //     max-height: 220px;
    //     img {
    //         height: 100%;
    //         width: auto;
    //         object-fit: cover;
    //     }
    // }
    // .slick-title {
    //     position: absolute;
    //     top: 10px;
    //     display: block;
    //     width: 100%;
    //     height: 1.5em;
    //     background-color: rgba(255, 255, 255, 0.1);
    // }
    // .slick-bg {
    //     display: none;
    // }
}

.slick-slide img {
    max-height: 400px
}


/********************/

@font-face {
    font-family: 'external-link-font';
    src: url("data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBSoAAAC8AAAAYGNtYXAXVtKHAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5ZvFacWoAAAF4AAAAhGhlYWQIUvSYAAAB/AAAADZoaGVhBq0DxgAAAjQAAAAkaG10eAoAAAAAAAJYAAAAFGxvY2EAKABWAAACbAAAAAxtYXhwAAgAFQAAAngAAAAgbmFtZRdPKjEAAAKYAAACCnBvc3QAAwAAAAAEpAAAACAAAwMAAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADpAAPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6QD//f//AAAAAAAg6QD//f//AAH/4xcEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAACAAD/6wLrAtYACgASAAAXESEVIxEhNTMRIQE3JyERJwcnAAEAlQIVa/0VAVDFbwFFcMRnFQLra/3qwP7WAbfFbv68b8VnAAABAAAAAQAAratF9V8PPPUACwQAAAAAANMkWG0AAAAA0yRYbQAA/+sC6wLWAAAACAACAAAAAAAAAAEAAAPA/8AAAAQAAAAAAALrAAEAAAAAAAAAAAAAAAAAAAAFBAAAAAAAAAAAAAAAAgAAAAQAAAAAAAAAAAoAFAAeAEIAAQAAAAUAEwACAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAA4ArgABAAAAAAABABIAAAABAAAAAAACAAcAwwABAAAAAAADABIAVwABAAAAAAAEABIA2AABAAAAAAAFAAsANgABAAAAAAAGABIAjQABAAAAAAAKABoBDgADAAEECQABACQAEgADAAEECQACAA4AygADAAEECQADACQAaQADAAEECQAEACQA6gADAAEECQAFABYAQQADAAEECQAGACQAnwADAAEECQAKADQBKGV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdFZlcnNpb24gMS4wAFYAZQByAHMAaQBvAG4AIAAxAC4AMGV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdGV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdFJlZ3VsYXIAUgBlAGcAdQBsAGEAcmV4dGVybmFsLWxpbmstZm9udABlAHgAdABlAHIAbgBhAGwALQBsAGkAbgBrAC0AZgBvAG4AdEZvbnQgZ2VuZXJhdGVkIGJ5IEljb01vb24uAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAGIAeQAgAEkAYwBvAE0AbwBvAG4ALgAAAAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nav-arrow-font';
    src: url("data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBFEAAAC8AAAAYGNtYXAXVtKIAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zht2s6kAAAF4AAAAjGhlYWQJw9mHAAACBAAAADZoaGVhBZADxwAAAjwAAAAkaG10eApyANYAAAJgAAAAGGxvY2EAbgBGAAACeAAAAA5tYXhwAAgACQAAAogAAAAgbmFtZZlKCfsAAAKoAAABhnBvc3QAAwAAAAAEMAAAACAAAwImAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADpAQPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6QH//f//AAAAAAAg6QD//f//AAH/4xcEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAGv//gHOAn4ABgAAEwEXBxcHAWsBMTLq6jL+zwE+AUBH+fJOAUAAAQBrAAABzgKAAAYAADcnNyc3CQGcMerqMQEy/s4ATvL5R/7A/sAAAAEAAAABAABTZTjlXw889QALBAAAAAAA1GtLBgAAAADUa0sGAAD//gHOAoAAAAAIAAIAAAAAAAAAAQAAA8D/wAAABAAAAAAAAc4AAQAAAAAAAAAAAAAAAAAAAAYEAAAAAAAAAAAAAAACAAAAAjkAawI5AGsAAAAAAAoAFAAeADIARgAAAAEAAAAGAAcAAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAHAAAAAQAAAAAAAgAHAGAAAQAAAAAAAwAHADYAAQAAAAAABAAHAHUAAQAAAAAABQALABUAAQAAAAAABgAHAEsAAQAAAAAACgAaAIoAAwABBAkAAQAOAAcAAwABBAkAAgAOAGcAAwABBAkAAwAOAD0AAwABBAkABAAOAHwAAwABBAkABQAWACAAAwABBAkABgAOAFIAAwABBAkACgA0AKRpY29tb29uAGkAYwBvAG0AbwBvAG5WZXJzaW9uIDEuMABWAGUAcgBzAGkAbwBuACAAMQAuADBpY29tb29uAGkAYwBvAG0AbwBvAG5pY29tb29uAGkAYwBvAG0AbwBvAG5SZWd1bGFyAFIAZQBnAHUAbABhAHJpY29tb29uAGkAYwBvAG0AbwBvAG5Gb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA") format("truetype");
    font-weight: normal;
    font-style: normal;
}

// #calculator {
//     text-rendering: optimizeLegibility;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

@media screen and (max-width: 992px) {
    .nowrap {
        white-space: nowrap;
    }
    .overflow-hidden {
        overflow: hidden;
    }
}

@media screen and (max-width: 480px) {
    span.button.a {
        display: block;
    }
    section#feature-brand {
        width: 100%;
        height: 10em;
        box-sizing: border-box;
    }
    h2,
    h3 {
        text-align: center;
    }
    section>section.middle {
        margin: 0;
        padding: 0;
    }
    section.middle>div {
        margin: 2rem 0 0 0;
        width: 100%;
    }
    #banner-ad {
        margin-bottom: 2em;
    }
    .block .box>a {
        padding: 0;
    }
    .box a>div {
        padding: 0;
    }
    .middle .block>div {
        padding: 0;
    }
    .btn-link>a {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .main-slick-img {
        height: 380px;
        max-height: 400px;
        object-fit: cover;
    }
    .main-slick-img>img {
        height: 100%;
        width: auto;
        object-fit: cover;
    }
    #home .slick-img {
        height: 200px;
        max-height: 220px;
        overflow: hidden;
    }
    #home .feature-blog .slick-list {
        height: unset;
        max-height: unset;
        overflow: hidden;
    }
}

@media screen and (max-width: 899px) {
    #calculator h1 {
        font-size: 44px;
    }
    .slide {
        width: 100%;
    }
    nav.main {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s ease-out;
        ul {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 1rem;
            ul {
                display: none;
            }
            li {
                width: 100%;
                display: block;
                padding: 1em 2em;
                border-bottom: 1px dashed rgb(45, 99, 157);
                &:hover {
                    background-color: rgba(45, 99, 157, 0.5);
                }
                i {
                    padding-right: 5px;
                }
            }
        }
        a {
            color: #ddd;
        }
        input.drop-concrete,
        .drop-concrete+a,
        #toggle {
            display: none;
        }
    }
    label.drop-concrete {
        color: #ddd;
    }
    .slick-title {
        position: absolute;
        top: 10px;
        text-shadow: 3px 1px 5px rgba(105, 111, 105, 0.8)
    }
    #banner-ad .slick-bg+p {
        position: absolute;
        top: 60px;
        box-sizing: border-box;
        width: 80vw;
        height: 150px;
        color: #000;
    }
    label.toggle {
        position: relative;
        padding: 30px 25px;
        cursor: pointer;
    }
    .nav-icon {
        background-color: #000;
        width: 30px;
        height: 5px;
        display: block;
        position: relative;
        transition: all 0.2s ease;
        &::before,
        &::after {
            position: absolute;
            content: "";
            background-color: #000;
            display: block;
            width: 30px;
            height: 5px;
            transition: all 0.2s ease;
        }
        &::before {
            top: 10px;
        }
        &::after {
            top: -10px;
        }
    }
    #toggle:checked+label.toggle {
        .nav-icon {
            transform: rotate(135deg);
            &::before,
            &::after {
                transform: rotate(90deg);
                top: 0;
            }
        }
    }
}

#calculator {
    h2 {
        font-size: 36px;
        color: #292929;
        text-transform: uppercase;
        margin-bottom: 0;
        line-height: 1.2em;
    }
    h3 {
        color: #505050;
        font-size: 26px;
        text-transform: uppercase;
        margin: 5px 0 0 0;
    }
    h4 {
        color: #505050;
        font-size: 20px;
        text-transform: uppercase;
        margin: 5px 0 0 0;
    }
}

.btn-reset,
.btn-submit {
    font-size: 14px !important;
    padding: 20px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
}

.btn {
    &-reset {
        background-color: #929292;
    }
    &-submit {
        background-color: #004d9a;
    }
}

.tighten {
    margin-bottom: 30px;
}

img {
    max-width: 100%;
}

a.btn[target="_blank"]:after {
    font-family: 'external-link-font';
    content: " \e900";
    line-height: 0;
    font-weight: normal;
}

#home {
    #sidebar-right {
        aside {
            h3 {
                display: block;
                width: 100%;
                min-height: 35px;
                color: #036;
                background-image: none;
                line-height: 1.5;
            }
            &>div>div {
                width: 100%;
                box-sizing: border-box;
                height: auto;
                text-align: center;
                background: #edf3f4;
                background-image: linear-gradient(to bottom, #ADD9E4 0%, #FFFFFF 100%);
                box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
                margin: 30px auto;
                padding: 8px;
            }
        }
    }
    section.concrete {
        grid-column: 1/-1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

.col-01,
.col-02,
.col-03,
.col-04 {
    box-shadow: 1px 1px 4px 1px rgba(54, 53, 53, 0.2);
}

.box-container {
    margin: 0.5rem 1rem;
}

@media all and (max-width:440px) {
    #sidebar-right {
        margin: 0 !important;
        padding: 0 !important;
    }
    .box-img {
        width: 100%;
        height: 200px;
        a {
            width: 100%;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}